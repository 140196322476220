<template>
  <div>
    <breadCrumb title="Shipping Policy" :menuList="menuList"></breadCrumb>

    <section class="mb-4">
      <div class="container">
        <div class="p-4 bg-white rounded shadow-sm overflow-hidden mw-100 text-left" >
          <template v-if="content.length > 0">
            <div v-html="content"></div>
          </template>
          <template v-else>
            <h1>Shipping Policy for {{ appName }}</h1>

            <p>Thank you for shopping at {{ appName }}. We are committed to delivering your order as quickly and efficiently as possible.</p>

            <h2>1. Order Processing</h2>
            <p>1.1. Orders are typically processed within [2] business days from the date of purchase.</p>
            <p>1.2. Orders placed on weekends or holidays will be processed on the next business day.</p>

            <h2>2. Shipping Methods</h2>
            <p>2.1. We offer several shipping options, including standard shipping, expedited shipping, and international shipping.</p>
            <p>2.2. Shipping costs and delivery times vary based on the selected shipping method and destination.</p>

            <h2>3. Tracking Information</h2>
            <p>3.1. Once your order has shipped, you will receive a shipping confirmation email with tracking information.</p>
            <p>3.2. You can track the status of your order by using the provided tracking number on our website or the carrier's website.</p>

            <h2>4. Shipping Delays</h2>
            <p>4.1. While we strive to deliver your order on time, unforeseen circumstances such as weather conditions or carrier delays may cause shipping delays.</p>
            <p>4.2. We will notify you of any significant delays and provide assistance to resolve the issue.</p>

            <h2>5. International Shipping</h2>
            <p>5.1. For international orders, customers are responsible for any customs duties, taxes, or import fees that may apply.</p>
            <p>5.2. Delivery times for international orders may vary depending on customs processing in the destination country.</p>

            <h2>6. Contact Us</h2>
            <p>If you have any questions about shipping or need assistance with your order, please contact us at suoper@tk-shop.pro.</p>

            <p>[End of Shipping Policy]</p>
          </template>
        </div>
      </div>
    </section>

  </div>
</template>
<script>
import breadCrumb from '@/components/breadCrumb'
import { getArticleLists } from '@/api/index'
export default {
  components: {
    breadCrumb
  },
  data() {
    return {
      menuList: [
        {
          title: '',
          path: ''
        },
      ],
      content: ''
    }
  },
  computed: {
    appName() {
      return this.$store.state.appName;
    }
  },
  mounted() {
    this.GetArticleData()
  },
  methods: {
    GetArticleData() {
      let f = new FormData()
      f.append('Type', '7')
      getArticleLists(f).then(res => {
        if(res.data.Items.length > 0){
          this.content = res.data.Items[0].Content
        }
      })
    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
  }
}
</script>